.SkillContainer{
height: 100%;
}

.skill{
    margin:3%;
}

.skill p{
    color: var(--color-second);
    font-family: var(--font-body);
    text-align: center;
}

.SkillContainer h3{
    font-size:25px;
    color: var(--color-second);
    text-align: center;
    font-family: var(--font-body);
    padding-top: 20px;
    
}

.Skill-List{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top:45px;
    padding-bottom: 40px;

}