.Navigation{
    background-color: var(--color-principal);
   
}
.NavContainer{
    display:flex;
    justify-content: space-between;
    
}

.Name h1{
    font-family: var(--font-name);
    color: var(--color-second);
    font-size: 35px;
    margin-left:35px;
    
}
.color-dot{
    color: #c97467;
}

.nav-btn{
    background-color: var(--color-second);
    font-family: var(--font-name);
    color: black;
    font-size: 14px;
    font-weight: 600;
    border: none;
   margin:15px;
}

.nav-btn:hover{
    background-color:#c97467;
    color: var(--color-second);
}

