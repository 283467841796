.AboutSection{
    
    height: 100%;
    background-color: var(--color-second);
}
.MoreAbout{
    max-width: 800px;
    display:flex;
    flex-direction: column;
    margin: 10% 25% 4% 25%;
    padding:5%;
}
.MoreAbout h3{
    font-size:25px;
    color: black;
    text-align: center;
    font-family: var(--font-body);
}

.MoreAbout p{
    font-size: 18px;
    color: black;
    font-family: var(--font-body);
    
}
.MoreAbout img{
    border: 3px solid #c97467;
    margin-bottom: 5%;
}
.responsive-andrea{
   
    max-width: 100%;
    /* max-width: 450px; */
    height: auto;
    
}