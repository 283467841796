.ProjectsContainer{
    height: 100%;
    background-color: var(--color-second);

}

.ProjectsList h4{
    font-size:25px;
    color: black;
    text-align: center;
    font-family: var(--font-body);
    padding-top: 50px;
}

.ProjectsList p{
    text-align: center;
    font-size: 18px;
    color: black;
    font-family: var(--font-body);
}

.projectCard{
    display: flex;
    flex-wrap: wrap;
    
}

.projectCard .Project-description{
    font-size: 16px;
}

.projectCard .tech-used{
    font-size: 14px;
}
.custom-btn{
    background-color: var(--color-first);
    border-color: var(--color-first);
    font-family: var(--font-name);
    font-size: 18px;
    margin:5px;
    

}

.custom-btn:hover{
    background-color:#c97467;
    border-color: #c97467;
}



