.FooterContainer {
  height: 20vh;
  display: flex;
  justify-content: space-between;
}

.FooterName h2 {
  font-family: var(--font-name);
  color: var(--color-second);
  font-size: 35px;
  margin-top: 25px;
  margin-left: 35px;
}

.socialMedia {
  margin-top: 10px;
  margin-right: 35px;
}
